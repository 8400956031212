function kebabToPascal(s) {
    const string = s.replace(/(-\w)/g, (x) => x[1].toUpperCase());
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function convertObjectKeys(obj, removeNull = true) {
    if (Array.isArray(obj)) {
        return obj.map(convertObjectKeys);
    }

    if (typeof obj === 'string') {
        return kebabToPascal(obj);
    }

    if (typeof obj !== 'object') {
        return obj;
    }

    let r = {};
    for (let key in obj) {
        if (removeNull && obj[key] === null) {
            continue;
        }

        r[kebabToPascal(key)] =
            obj[key] && typeof obj[key] === 'object'
                ? convertObjectKeys(obj[key])
                : obj[key];
    }

    return r;
}
