/**
 * PageArticle
 */

import React from 'react';
import HeroArticle from 'Components/HeroArticle';
import Wysiwyg from 'Components/Wysiwyg';
import Breadcrumbs from 'Components/Breadcrumbs';
import StreamField from 'Components/StreamField';
import ButtonAI from 'Components/ButtonAI';

import { BaseWrap } from 'Containers/Layouts';
import SubMenu from 'Components/SubMenu';
import s from './PageArticle.module.scss';

class PageArticle extends React.PureComponent {
    render() {
        const {
            hero,
            wysiwyg,
            breadcrumbs,
            items,
            fullwidthHeroImage,
            id,
            submenu,
            ai,
        } = this.props;

        return (
            <div className={s['PageArticle']}>
                {submenu &&
                    <SubMenu {...submenu} />
                }
                <Breadcrumbs {...breadcrumbs} />
                <HeroArticle {...hero} fullwidthImage={fullwidthHeroImage} />

                {ai &&
                    <ButtonAI {...ai} modifiers={["Wrap"]} />
                }

                {wysiwyg && (
                    <div className={s['PageArticle__Wysiwyg']}>
                        <Wysiwyg wysiwyg={wysiwyg} />
                    </div>
                )}

                {items && <StreamField items={items} currentPage={id} />}
            </div>
        );
    }
}

PageArticle.defaultProps = {
    items: [],
};

export default BaseWrap(PageArticle);
