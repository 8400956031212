/**
 * SubMenu
 */

import React, {useState, useEffect, useRef, Fragment} from 'react';
import classNames from 'classnames';
import { Waypoint } from 'react-waypoint';
import * as Scroll from 'react-scroll';
import s from './SubMenu.module.scss';

const SubMenu = ({
    current,
    items = [],
    breakpoint ='XS',
    scrollOffset = 0,
}) => {
    const wrapperRef = useRef();
    const itemsListRef = useRef();

    const [open, setOpen] = useState(false);
    const [subSubMenuOpen, setSubSubMenuOpen] = useState(false);
    const [sticky, setSticky] = useState(false);
    const [breakItemIndex, setBreakItemIndex] = useState(100);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setMounted(true);
        }
    }, []);

    useEffect(() => {
        if(mounted) {
            document.addEventListener('mousedown', handleClickOutside);
            updateBreakItemIndex();

            let timeout;
            window.onresize = () => {
                clearTimeout(timeout);
                timeout = setTimeout(updateBreakItemIndex, 100);
            };
        };
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [mounted]);

    const handleClickOutside = (e) => {
        if (subSubMenuOpen && wrapperRef && wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            setSubSubMenuOpen(false);
        }
    };

    const handleLinkClick = (href) => {
        if (href.startsWith('#')) {
            Scroll.scroller.scrollTo(href.replace('#', ''), {
                duration: 350,
                smooth: 'easeIn',
                offset: scrollOffset,
            });
            setSubSubMenuOpen(false);
            setOpen(false);
        }
    };

    const updateBreakItemIndex = () => {
        if (!itemsListRef || !itemsListRef.current) {
            return;
        }

        let subMenuItemsWidth = 0;
        let itemCounter = 0;
        const estimatedReadMoreButtonWidth = 105;
        const submenuWrapperWidth = itemsListRef.current.offsetWidth;
        const subMenuItems = itemsListRef.current.childNodes;
        let newItemIndex = items.length;

        for (const element of subMenuItems) {
            if ((subMenuItemsWidth + element.offsetWidth) < (submenuWrapperWidth - estimatedReadMoreButtonWidth)) {
                subMenuItemsWidth += element.offsetWidth;
                itemCounter = itemCounter + 1;
            }
            else {
                newItemIndex = itemCounter;
                break;
            }
        }

        setBreakItemIndex(newItemIndex);
    };

    if (!current || !items) {
        return null;
    }

    const overflowItems = items.slice(breakItemIndex);
    const nonOverflowingItems = items.filter((item) => !overflowItems.includes(item));

    const classes = classNames(
        s['SubMenu'],
        {[s[`SubMenu--Breakpoint${breakpoint}`]]: breakpoint},
    );

    return (
        <div className={classes}>
            {mounted && (
                <Fragment>
                    <Waypoint
                        topOffset={1}
                        onEnter={() => setSticky(false)}
                        onLeave={() => setSticky(true)}
                    />
                    <div className={classNames(
                        s['SubMenu__Content'],
                        {[s['SubMenu__Content--Sticky']]: sticky},
                        {[s['SubMenu__Content--Open']]: open},
                    )}>
                        <p className={classNames(
                            s['SubMenu__Title'],
                            {[s['SubMenu__Title--Open']]: open},
                        )} onClick={() => setOpen(!open)}>
                            {current.title}
                        </p>
                        <ul
                            ref={itemsListRef}
                            className={classNames(
                                s['SubMenu__Items'],
                                {[s['SubMenu__Items--Open']]: open},
                            )}
                        >
                            {nonOverflowingItems.map(({ title, href, id }, i) => <li
                                key={i}
                                className={classNames(
                                    s['SubMenu__Item'],
                                    {[s['SubMenu__Item--Current']]: current.id === id},
                                    {[s['SubMenu__Item--First']]: i === 0},
                                )}
                            >
                                <a
                                    href={href}
                                    onClick={() => handleLinkClick(href)}
                                    className={classNames(
                                        s['SubMenu__Link'],
                                        {[s['SubMenu__Link--Current']]: current.id === id},
                                        {[s['SubMenu__Link--First']]: i === 0},
                                    )}
                                >{title}</a>
                            </li>)}
                            {!!overflowItems.length &&
                        <li className={classNames(s['SubMenu__SubMenu'], s['SubMenu__Item'])} ref={wrapperRef}>
                            <button
                                className={classNames(
                                    s['SubMenu__SubMenuToggle'],
                                    s['SubMenu__Link'],
                                    {[s['SubMenu__SubMenuToggle--Open']]: subSubMenuOpen}
                                )}
                                type="button"
                                onClick={() => setSubSubMenuOpen(!subSubMenuOpen)}
                            >
                                Visa fler
                            </button>
                            <ul
                                aria-expanded={subSubMenuOpen ? 'true' : 'false'}
                                aria-hidden={subSubMenuOpen ? 'false' : 'true'}
                                className={classNames(
                                    s['SubMenu__SubMenuItems'],
                                    {[s['SubMenu__SubMenuItems--Open']]: subSubMenuOpen},
                                )}
                            >
                                {overflowItems.map(({ title, href, id }, i) => <li
                                    key={i}
                                    className={classNames(
                                        s['SubMenu__Item'],
                                        s['SubMenu__Item--Sub'],
                                        {[s['SubMenu__Item--Current']]: current.id === id},
                                    )}
                                >
                                    <a
                                        href={href}
                                        onClick={() => handleLinkClick(href)}
                                        className={classNames(
                                            s['SubMenu__Link'],
                                            s['SubMenu__Link--Sub'],
                                            {[s['SubMenu__Link--Current']]: current.id === id}
                                        )}
                                    >{title}</a>
                                </li>)}
                            </ul>
                        </li>}
                            {overflowItems.map(({ title, href, id }, i) => <li
                                key={i}
                                className={classNames(
                                    s['SubMenu__Item'],
                                    {[s['SubMenu__Item--Overflow']]: overflowItems.some(el => el.title === title)},
                                    {[s['SubMenu__Item--Current']]: current.id === id},
                                )}
                            >
                                <a
                                    href={href}
                                    onClick={() => handleLinkClick(href)}
                                    className={classNames(
                                        s['SubMenu__Link'],
                                        {[s['SubMenu__Link--Current']]: current.id === id},
                                    )}
                                >{title}</a>
                            </li>
                            )}
                        </ul>
                    </div>
                </Fragment>
            )}
        </div>
    );
};

export default SubMenu;
